import objFilter from 'object-filter';

import { fetchData, fetchDataLength } from '../components/Data/actions';

function prepareQuery(dispatch, filter, data) {
  const query = {
    transition: filter.transitionTypes,
    wild: filter.wild,
    mut: filter.mut,
  };
  const queryAddDiseaseNameAndFilterFalsey = {
    ...objFilter(query, val => !!val),
    Disease_name: filter.search.searchText,
    Protein_names: filter.search.searchText,
    sorted: data.sorted,
  };
  const apiReadyQuery = filter.variantTypes.length
    ? { ...queryAddDiseaseNameAndFilterFalsey, Variant_Type: { $in: filter.variantTypes } }
    : { ...queryAddDiseaseNameAndFilterFalsey };
  return apiReadyQuery;
}

export function updateData(dispatch, filter, data) {
  const apiReadyQuery = prepareQuery(dispatch, filter, data);

  dispatch(
    fetchData({
      query: apiReadyQuery,
      mpage: {
        mstart: '' + (data.currentDataPage * data.dataItemsPerPage - data.dataItemsPerPage),
        mrange: '' + data.dataItemsPerPage,
      },
    }),
  );
}

export function updateDataLength(dispatch, filter, data) {
  const apiReadyQuery = prepareQuery(dispatch, filter, data);

  dispatch(fetchDataLength(apiReadyQuery));
}

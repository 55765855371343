import React, { Component } from 'react';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

import { BrowserRouter as Router, Route } from 'react-router-dom';

import rootReducer from './rootReducer';

import './scss/app.scss';

import DataContainer from './components/Data/DataContainer';
import Introduction from './components/Introduction/Introduction';
import Filters from './components/Filtering/Filters';
import About from './components/About/About';

const logger = createLogger({
  diff: true,
  collapsed: true,
});

class App extends Component {
  render() {
    let store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, logger)));
    return (
      <Provider store={store}>
        <Router>
          <div className="base-container">
            <div className="left-panel">
              <Introduction />
              <Route exact={true} path="/" component={Filters} />
            </div>
            <div className="right-panel">
              <Route exact={true} path="/" component={DataContainer} />
              <Route path="/about" component={About} />
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;

import axios from 'axios';

export function fetchData(query) {
  return async dispatch => {
    dispatch(fetchingData(true));
    console.log(process.env.API_BASE_URL)
    try {
      const res = await axios
        .post(
          // 'http://localhost:3030/api/data/mutations',
          `${process.env.API_BASE_URL || 'https://api.d2odb.org'}/api/data/mutations`, query);
      return dispatch(fetchDataSuccess(res));
    }
    catch (error) {
      return dispatch(fetchDataError(error));
    }
  };
}

export function fetchDataLength(query) {
  return async dispatch => {
    dispatch(fetchingDataLength(true));
    try {
      const res = await axios
        .post(
          // 'http://localhost:3030/api/data/mutations/length',
          `${process.env.API_BASE_URL || 'https://api.d2odb.org'}/api/data/mutations/length`, query);
      return dispatch(fetchDataLengthSuccess(res));
    }
    catch (error) {
      return dispatch(fetchDataLengthError(error));
    }
  };
}

function fetchingData() {
  return {
    type: 'FETCHING_DATA',
    isLoading: true,
  };
}

function fetchDataSuccess(res) {
  return {
    type: 'FETCH_DATA_SUCCESS',
    data: res,
  };
}

function fetchDataError(err) {
  return {
    type: 'FETCH_DATA_ERROR',
    err: err,
  };
}

function fetchingDataLength() {
  return {
    type: 'FETCHING_DATA_LENGTH',
    isLoading: true,
  };
}

function fetchDataLengthSuccess(res) {
  return {
    type: 'FETCH_DATA_LENGTH_SUCCESS',
    data: res,
  };
}

function fetchDataLengthError(err) {
  return {
    type: 'FETCH_DATA_LENGTH_ERROR',
    err: err,
  };
}

export function nextPage() {
  return {
    type: 'NEXT_PAGE',
  };
}

export function previousPage() {
  return {
    type: 'PREVIOUS_PAGE',
  };
}

export function sort(value) {
  return {
    type: 'SORT',
    value: value,
  };
}

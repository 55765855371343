import React from 'react';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core';

import logo from '../../d2odb_logo.png';

import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';

const svg = (
  <svg height="128" width="128" viewBox="0 0 128 128">
    <path d="M104 64c-8 0-24-5-24-20 0-2.9.6-5.3 1.6-7.5C85.8 45.7 97.6 49 104 49zM104 89c-8 0-24-5-24-20 0-2.9.6-5.3 1.6-7.5C85.8 70.7 97.6 74 104 74z" fill="#ccc"/>
    <path d="M82.2 94.7c-.1 3.5 1.2 7.1 3.6 9.7 1.4 1.5 3.1 2.6 4.5 4.1.7.7 1.2 1.6 1.6 2.5.4 1 .5 2 .3 3-.2 1.1-1 2.1-2 2.8a6 6 0 0 1-3.2 1c-1.2 0-2.3-.2-3.4-.7-1-.5-2-1.1-3-1.9-1.8-1.4-3.3-3.2-5-4.8-1.7-1.6-3.7-3-6-3.5-1.9-.3-3.8 0-5.6.6l-5.4 1.7c-3.9 1.1-8 1-12 .2-3.9-.8-7.6-2.5-11-4.6-3.7-2.2-7-5-9.7-8.3a26.1 26.1 0 0 1-5.4-11.6c-1.1-6.5.7-13.3 4.3-18.9 3.6-5.5 9-9.8 14.8-13 4.8-2.4 10-4.2 14.9-6.5a31 31 0 0 0 12.7-10 19 19 0 0 0 3.1-8.3c.4-3 0-6-1.3-8.8a15.5 15.5 0 0 0-5.7-6.8 14.2 14.2 0 0 0-8.6-2.2c-3.2.2-6.3 1.6-9 3.6-3.3 2.5-6 5.8-8.5 9-2.6 3.3-5.1 6.7-8.4 9.3-3.4 2.7-7.4 4.5-11 6.8a16 16 0 0 0-6.7 6.8 8.2 8.2 0 0 0-.6 4.7c.3 1.5 1.2 3 2.5 4 1.4.9 3.3 1.2 5 .9 1.6-.4 3.2-1.2 4.5-2.2 2.7-2.1 4.7-5 7.4-7.1a19.7 19.7 0 0 1 15.8-3.8c5.6 1 10.6 4.2 14.3 8.4 2 2.2 3.5 4.7 4.6 7.4 1 2.7 1.5 5.6 1.3 8.5-.3 2.9-1.4 5.7-3.3 8a12.1 12.1 0 0 1-7.4 4.1c-2.2.3-4.3 0-6.5 0-1 .1-2.2.3-3.2.7-1 .4-2 1-2.6 1.8a5.7 5.7 0 0 0-1 4.2c.2 1.5.9 2.9 1.7 4 1.7 2.5 4.2 4.3 6 6.6a16 16 0 0 1-1.7 21.2" fill="none" stroke="#003380" strokeWidth="4"/>
    <path d="M100.7 35.7a73.8 73.8 0 0 1-14.5-11.5c-1.3-1.3-2.5-2.6-3.9-3.8a12 12 0 0 0-4.7-2.5c-1.5-.4-3.2-.4-4.7 0-1.6.4-3 1-4.5 1.8-2.7 1.7-5.1 3.8-7.8 5.6a26.3 26.3 0 0 1-14.3 4.6 19 19 0 0 1-13.8-5.6c-1.8-2-3.2-4.3-4-7" fill="none" stroke="#003380" strokeWidth="4"/>
    <path d="M104 24c-8 0-24 5-24 20 0 2.9.6 5.3 1.6 7.5C85.8 42.3 97.6 39 104 39zM104 49c-8 0-24 5-24 20 0 2.9.6 5.3 1.6 7.5C85.8 67.3 97.6 64 104 64zM104 74c-8 0-24 5-24 20 0 2.9.6 5.3 1.6 7.5C85.8 92.3 97.6 89 104 89z" fill="#003380"/>
    <circle r="12" cy="58" cx="110" fill="red" stroke="#fff" strokeWidth="4" strokeLinejoin="round"/>
  </svg>
)

class Introduction extends React.Component {
  render() {
    return (
      <div>
        <div
          style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
        >
          <div style={{ marginRight: '1rem' }}>
            {svg}
          </div>
          <h1 style={{ color: '#003380' }}>D2Odb</h1>
        </div>
        <p>A database of predicted structural transitions for mutations in human proteins.</p>
        <Button
          component={NavLink}
          exact
          to="/"
          size="small"
          activeStyle={{
            fontWeight: 'bold',
          }}
        >
          <HomeIcon className="button-icon" />
          Home
        </Button>
        <Button
          component={NavLink}
          to="/about"
          size="small"
          activeStyle={{
            fontWeight: 'bold',
          }}
        >
          <HelpIcon className="button-icon" />
          About
        </Button>
      </div>
    );
  }
}

export default Introduction;

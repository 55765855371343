import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import Link from '@material-ui/core/Link';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';

import Fade from '@material-ui/core/Fade';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import 'react-table/react-table.css';

import { updateData } from '../../utils/updateData';

import { nextPage, previousPage, sort } from './actions';

import Popper from '@material-ui/core/Popper';

import CircularProgress from '@material-ui/core/CircularProgress';

import 'litemol/dist/css/LiteMol-plugin-light.css';
import LiteMol from 'litemol';

import 'unfetch/polyfill';

const PaginationContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px;
`;
const PaginationCounter = styled.div``;

class DetailPopper extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: state.anchorEl ? null : currentTarget,
    }));
  };

  handleClickAway = () => {
    this.setState(() => ({
      anchorEl: null,
    }));
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'no-transition-popper' : null;

    const popper = (
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        disablePortal={false}
        className="detail-popper"
        placement={this.props.placement}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className="detail-popper-paper">
              <ClickAwayListener onClickAway={this.handleClickAway}>
                {this.props.children}
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    );

    if (this.props.isIcon) {
      return (
        <div>
          <IconButton aria-label="Help" onClick={this.handleClick}>
            <HelpIcon />
          </IconButton>
          {popper}
        </div>
      );
    }
    return (
      <div>
        <Link component="button" aria-label="Help" onClick={this.handleClick}>
          {this.props.title}
        </Link>
        {popper}
      </div>
    );
  }
}

class DetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <React.Fragment>
        <Link component="button" onClick={this.handleOpen}>
          {this.props.title}
        </Link>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby={this.props.title}
          aria-describedby={this.props.subtitle}
          onRendered={this.props.onRendered}
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <List>
              <ListItem>
                <ListItemText
                  primary={this.props.subtitle}
                />
                <ListItemSecondaryAction>
                  <DetailPopper title={this.props.subtitle} placement="bottom-end" isIcon>
                    <Typography>
                      A predictor voting strategy was employed to determine the prediction outcome for each mutation. As there are multiple predictors for protein disorder, the residues were deemed to be located within disordered regions if the number of predictors assigning residues to disordered regions were equal to or larger than the number of predictors assigning the residues to ordered regions. For VLS2B and IUPred, residues with predicted scores equal to or above 0.5 were considered to be located in disordered regions. For Dynamine, Residues with predicted scores less than or equal to 0.69 are considered to be located in disordered regions, while those with scores greater than or equal to 0.8 are predicted to be in the structured regions.
                    </Typography>
                  </DetailPopper>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            {this.props.children}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

class DetailModalTransition extends React.Component {
  render() {
    return (
      <DetailModal {...this.props}>
        <List>
          <ListItem divider>
            <ListItemText disableTypography>
              <Typography variant="subtitle1">Wild</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={this.props.prediction_scores.VSL2b_wild.toFixed(2)}
              secondary="VSL2b"
            />
            <ListItemSecondaryAction>
              <DetailPopper title="Help" placement="bottom-end" isIcon>
                <Typography>
                  VSL2B [<a href="/about#2">2</a>] is a widely used sequence-based predictor for
                  intrinsically disordered regions, using Support Vector Machine (SVM). Residues
                  with predicted scores equal to or above 0.5 are considered to be disordered.
                </Typography>
              </DetailPopper>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={this.props.prediction_scores.IUPred_s_wild.toFixed(2)}
              secondary="IUPred-S"
            />
            <ListItemSecondaryAction>
              <DetailPopper title="Help" placement="bottom-end" isIcon>
                <Typography>
                  IUPred (
                  <a
                    href="http://iupred.enzim.hu/"
                    target="_blank"
                    rel="external noopener noreferrer"
                  >
                    http://iupred.enzim.hu/
                  </a>{' '}
                  [<a href="/about#3">3</a>, <a href="/about#4">4</a>]) maintains two versions of
                  IUPred including IUPred-S and IUPred-L. Here, ‘S’ and ‘L’ refer to the long LDRs
                  and SDRs, respectively. For the ‘S’ option, the model was trained using a dataset
                  corresponding to missing residues in the protein structures. These residues are
                  absent from the protein structures due to missing electron density in the
                  corresponding X-ray crystal structures. These disordered regions are usually
                  short. Conversely for the ‘L’ option, the dataset used to train models corresponds
                  to long disordered regions that are validated by various experimental techniques.
                  In our study, residues with predicted scores equal to or above 0.5 were considered
                  to be located in disordered regions.
                </Typography>
              </DetailPopper>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={this.props.prediction_scores.IUPred_l_wild.toFixed(2)}
              secondary="IUPred-L"
            />
            <ListItemSecondaryAction>
              <DetailPopper title="Help" placement="bottom-end" isIcon>
                <Typography>
                  IUPred (
                  <a
                    href="http://iupred.enzim.hu/"
                    target="_blank"
                    rel="external noopener noreferrer"
                  >
                    http://iupred.enzim.hu/
                  </a>{' '}
                  [<a href="/about#3">3</a>, <a href="/about#4">4</a>]) maintains two versions of
                  IUPred including IUPred-S and IUPred-L. Here, ‘S’ and ‘L’ refer to the long LDRs
                  and SDRs, respectively. For the ‘S’ option, the model was trained using a dataset
                  corresponding to missing residues in the protein structures. These residues are
                  absent from the protein structures due to missing electron density in the
                  corresponding X-ray crystal structures. These disordered regions are usually
                  short. Conversely for the ‘L’ option, the dataset used to train models corresponds
                  to long disordered regions that are validated by various experimental techniques.
                  In our study, residues with predicted scores equal to or above 0.5 were considered
                  to be located in disordered regions.
                </Typography>
              </DetailPopper>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={this.props.prediction_scores.Dynamine_wild.toFixed(2)}
              secondary="DynaMine"
            />
            <ListItemSecondaryAction>
              <DetailPopper title="Help" placement="bottom-end" isIcon>
                <Typography>
                  DynaMine [<a href="/about#5">5</a>], which is trained with a curated nuclear
                  magnetic resonance (NMR) dataset, was used to predict protein disordered regions
                  with only sequence information as the input. Residues with predicted scores less
                  than or equal to 0.69 are considered to be located in disordered regions, while
                  those with scores greater than or equal to 0.8 are predicted to be in the
                  structured regions.
                </Typography>
              </DetailPopper>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem divider>
            <ListItemText disableTypography>
              <Typography variant="subtitle1">Mutant</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={this.props.prediction_scores.VSL2b_mut.toFixed(2)}
              secondary="VSL2b"
            />
            <ListItemSecondaryAction>
              <DetailPopper title="Help" placement="bottom-end" isIcon>
                <Typography>
                  VSL2B [<a href="/about#2">2</a>] is a widely used sequence-based predictor for
                  intrinsically disordered regions, using Support Vector Machine (SVM). Residues
                  with predicted scores equal to or above 0.5 are considered to be disordered.
                </Typography>
              </DetailPopper>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={this.props.prediction_scores.IUPred_s_mut.toFixed(2)}
              secondary="IUPred-S"
            />
            <ListItemSecondaryAction>
              <DetailPopper title="Help" placement="bottom-end" isIcon>
                <Typography>
                  IUPred (
                  <a
                    href="http://iupred.enzim.hu/"
                    target="_blank"
                    rel="external noopener noreferrer"
                  >
                    http://iupred.enzim.hu/
                  </a>{' '}
                  [<a href="/about#3">3</a>, <a href="/about#4">4</a>]) maintains two versions of
                  IUPred including IUPred-S and IUPred-L. Here, ‘S’ and ‘L’ refer to the long LDRs
                  and SDRs, respectively. For the ‘S’ option, the model was trained using a dataset
                  corresponding to missing residues in the protein structures. These residues are
                  absent from the protein structures due to missing electron density in the
                  corresponding X-ray crystal structures. These disordered regions are usually
                  short. Conversely for the ‘L’ option, the dataset used to train models corresponds
                  to long disordered regions that are validated by various experimental techniques.
                  In our study, residues with predicted scores equal to or above 0.5 were considered
                  to be located in disordered regions.
                </Typography>
              </DetailPopper>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={this.props.prediction_scores.IUPred_l_mut.toFixed(2)}
              secondary="IUPred-L"
            />
            <ListItemSecondaryAction>
              <DetailPopper title="Help" placement="bottom-end" isIcon>
                <Typography>
                  IUPred (
                  <a
                    href="http://iupred.enzim.hu/"
                    target="_blank"
                    rel="external noopener noreferrer"
                  >
                    http://iupred.enzim.hu/
                  </a>{' '}
                  [<a href="/about#3">3</a>, <a href="/about#4">4</a>]) maintains two versions of
                  IUPred including IUPred-S and IUPred-L. Here, ‘S’ and ‘L’ refer to the long LDRs
                  and SDRs, respectively. For the ‘S’ option, the model was trained using a dataset
                  corresponding to missing residues in the protein structures. These residues are
                  absent from the protein structures due to missing electron density in the
                  corresponding X-ray crystal structures. These disordered regions are usually
                  short. Conversely for the ‘L’ option, the dataset used to train models corresponds
                  to long disordered regions that are validated by various experimental techniques.
                  In our study, residues with predicted scores equal to or above 0.5 were considered
                  to be located in disordered regions.
                </Typography>
              </DetailPopper>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={this.props.prediction_scores.Dynamine_mut.toFixed(2)}
              secondary="DynaMine"
            />
            <ListItemSecondaryAction>
              <DetailPopper title="Help" placement="bottom-end" isIcon>
                <Typography>
                  DynaMine [<a href="/about#5">5</a>], which is trained with a curated nuclear
                  magnetic resonance (NMR) dataset, was used to predict protein disordered regions
                  with only sequence information as the input. Residues with predicted scores less
                  than or equal to 0.69 are considered to be located in disordered regions, while
                  those with scores greater than or equal to 0.8 are predicted to be in the
                  structured regions.
                </Typography>
              </DetailPopper>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DetailModal>
    );
  }
}

class DetailModalProtein extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      pdb_ids: [],
      value: null,
    };
    // create a ref to store the textInput DOM element
    this.litemol = React.createRef();

    // This binding is necessary to make `this` work in the callback
    this.handleChange = this.handleChange.bind(this);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (event, value) => {
    this.setState({ value });

    this.plugin.destroy();
    this.plugin = this.createPlugin();

    this.initializePlugin(value);
  };

  render() {
    return (
      <React.Fragment>
        <Link component="button" onClick={this.handleOpen}>
          {this.props.title}
        </Link>
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="Protein viewer"
          aria-describedby="View the 3D structure of the protein"
          onEntered={() => {
            this.createPlugin = () =>
              LiteMol.Plugin.create({
                target: this.litemol.current,
                viewportBackground: '#000',
                layoutState: {
                  hideControls: true,
                  isExpanded: false,
                },
                // Knowing how often and how people use LiteMol
                // gives us the motivation and data to futher improve it.
                //
                // This option is OFF by default!
                allowAnalytics: false,
              });
            this.initializePlugin = pdb_id => {
              if (pdb_id) {
                this.plugin.loadMolecule({
                  id: pdb_id.id,
                  url: pdb_id.url,
                  format: pdb_id.format,
                });
              }
            };

            // Programmatic access - Batch retrieval of entries
            // https://www.uniprot.org/help/api_batch_retrieval

            // UniProtID=O60481 && \
            // curl --request POST \
            //      --data "from=ACC+ID&to=PDB_ID&format=tab&query=$UniProtID" \
            //      --header "Content-Type: application/x-www-form-urlencoded" \
            //      --location
            //      https://www.uniprot.org/uploadlists/

            var uniProtUrl = 'https://www.uniprot.org/uploadlists/';
            var params = `from=ACC+ID&to=PDB_ID&format=tab&query=${this.props.UniProtID}`;

            var getPdbe = fetch(uniProtUrl, {
              method: 'POST',
              headers: {
                'Content-type': 'application/x-www-form-urlencoded',
              },
              body: params,
            })
              .then(response => response.text())
              .then(text => {
                var pdb_ids = [];
                var list = text.split('\n');
                var i;
                for (i = 1; i < list.length - 1; i++) {
                  var id = list[i].split('\t')[1].toLowerCase();
                  var url = `https://www.ebi.ac.uk/pdbe/static/entry/${id}_updated.cif`;
                  pdb_ids.push({
                    id: `${id} (PDBe #${i})`,
                    url: url,
                    format: 'cif',
                  });
                }
                return pdb_ids;
              });

            var smrUrl = `https://swissmodel.expasy.org/repository/uniprot/${
              this.props.UniProtID
            }.json`;

            var getSmr = fetch(smrUrl, {
              method: 'GET',
            })
              .then(response => response.json())
              .then(json => {
                var structures = json.result.structures;
                var pdb_ids = [];
                var i;
                for (i = 0; i < structures.length; i++) {
                  pdb_ids.push({
                    id: `${structures[i].template} (SMR #${i + 1})`,
                    url: structures[i].coordinates,
                    format: 'pdb',
                  });
                }
                return pdb_ids;
              });
            Promise.all([getPdbe, getSmr]).then(values => {
              var allValues = [...values[0], ...values[1]];
              this.setState({ pdb_ids: allValues });
              this.setState({ value: allValues[0] });
              if (this.state.open) {
                this.plugin = this.createPlugin();
                this.initializePlugin(allValues[0]);
              }
            });
          }}
          onExit={() => {
            if (this.plugin) {
              this.plugin.destroy();
            }
            this.setState({ pdb_ids: [] });
            this.setState({ value: null });
          }}
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <Tabs
              value={this.state.value ? this.state.value : this.state.pdb_ids[0]}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {this.state.pdb_ids.map(number => (
                <Tab key={number.id} label={number.id} value={number} />
              ))}
            </Tabs>
            <div ref={this.litemol} className="litemol-wrapper" value={this.props.value}>
              <CircularProgress />
            </div>
            {this.props.children}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

class DataTable extends React.Component {
  renderPagination = () => {
    const { data } = this.props;
    return (
      <PaginationContainer>
        <Button onClick={this.handlePreviousPageClick} variant="contained">
          Previous
        </Button>
        <PaginationCounter>
          Page {data.currentDataPage} of{' '}
          { Math.ceil(data.numberOfDatapointsWithinFilter.total / data.dataItemsPerPage) }
        </PaginationCounter>
        <Button onClick={this.handleNextPageClick} variant="contained">
          Next
        </Button>
      </PaginationContainer>
    );
  };

  handleNextPageClick = () => {
    const { dispatch, filter, data } = this.props;
    const numberOfPages = Math.ceil(data.numberOfDatapointsWithinFilter.total / data.dataItemsPerPage);
    if (data.currentDataPage < numberOfPages) {
      dispatch(nextPage());
      updateData(dispatch, filter, { ...data, currentDataPage: data.currentDataPage + 1 });
    }
  };

  handlePreviousPageClick = () => {
    const { dispatch, filter, data } = this.props;
    if (data.currentDataPage > 1) {
      dispatch(previousPage());
      updateData(dispatch, filter, { ...data, currentDataPage: data.currentDataPage - 1 });
    }
  };

  render() {
    const { data } = this.props;
    var mim = /MIM:[0-9]{6}/;
    function minCode(value) {
      return mim.exec(value);
    }
    const col_Disease_name = row => {
      var mimTest = mim.test(row.value);
      if (mimTest) {
        return (
          <span>
            <DetailPopper title={row.value} placement="bottom-start">
              <Typography>{row.value}</Typography>
              <Button
                href={
                  'https://www.omim.org/entry/' +
                  minCode(row.value)[0].substring(4, minCode(row.value)[0].length)
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                OMIM
              </Button>
            </DetailPopper>
          </span>
        );
      }
      if (row.value !== '-') {
        return (
          <span>
            <DetailPopper title={row.value} placement="bottom-start">
              <Typography>{row.value}</Typography>
            </DetailPopper>
          </span>
        );
      }
      return <span>{row.value}</span>;
    };
    const columns = [
      {
        Header: 'Data',
        columns: [
          {
            Header: 'Transition',
            accessor: 'transition',
            width: 90,
            Cell: row => (
              <span>
                <DetailModalTransition
                  title={row.value.transition}
                  subtitle="Prediction scores"
                  prediction_scores={row.value.prediction_scores}
                />
              </span>
            ),
          },
          {
            Header: 'Protein',
            accessor: 'Protein_names',
            width: 400,
            Cell: row => (
              <DetailModalProtein title={row.value.Protein_names} UniProtID={row.value.UniProtID}>
                <Button
                  href={'https://www.uniprot.org/uniprot/' + row.value.UniProtID}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  UniProt
                </Button>
                <Button
                  href={'https://www.ncbi.nlm.nih.gov/snp/' + row.value.dbSNP}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  dbSNP
                </Button>
              </DetailModalProtein>
            ),
          },
          {
            Header: 'Disease',
            accessor: 'Disease_name',
            width: 250,
            fontSize: 12,
            Cell: col_Disease_name,
          },
          {
            Header: 'Variant type',
            accessor: 'Variant_Type',
            width: 120,
          },
          {
            Header: 'WT',
            accessor: 'wild',
            width: 40,
          },
          {
            Header: 'Position',
            accessor: 'pos',
            width: 80,
          },
          {
            Header: 'Mutant',
            accessor: 'mut',
            width: 80,
          },
        ],
      },
    ];
    const dataForTable = Object.keys(data.currentData)
      .sort((a, b) => a - b)
      .map(key => {
        const record = data.currentData[key];
        const Protein_names = {
          Protein_names: record.Protein_names,
          UniProtID: record.UniProtID,
          dbSNP: record.dbSNP,
        };
        const prediction_scores = {
          VSL2b_wild: record.VSL2b_wild,
          IUPred_s_wild: record.IUPred_s_wild,
          IUPred_l_wild: record.IUPred_l_wild,
          Dynamine_wild: record.Dynamine_wild,
          VSL2b_mut: record.VSL2b_mut,
          IUPred_s_mut: record.IUPred_s_mut,
          IUPred_l_mut: record.IUPred_l_mut,
          Dynamine_mut: record.Dynamine_mut,
        };
        const transition = {
          transition: record.transition,
          prediction_scores: {
            ...prediction_scores,
          },
        };
        return {
          transition: transition,
          Protein_names: Protein_names,
          Disease_name: record.Disease_name,
          Variant_Type: record.Variant_Type,
          wild: record.wild,
          pos: record.pos,
          mut: record.mut,
        };
      });
    return (
      <ReactTable
        data={dataForTable}
        columns={columns}
        className="-striped -highlight"
        multiSort={false}
        manual={true}
        onFetchData={state => {
          // show the loading overlay
          const { dispatch, filter, data } = this.props;
          updateData(dispatch, filter, { ...data, sorted: state.sorted });
        }}
        onSortedChange={sorted => {
          const { dispatch } = this.props;
          dispatch(
            sort({
              value: sorted,
            }),
          );
        }}
        // style={{
        //   height: "100vh" // This will force the table body to overflow and scroll, since there is not enough room
        // }}
        pageSize={40}
        // pageSize={data.dataItemsPerPage}
        loading={data.isLoadingData}
        PaginationComponent={this.renderPagination}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.data,
  filter: state.filter,
});

export default connect(mapStateToProps)(DataTable);

const initialDataState = {
  currentData: [],
  currentDataPage: 1,
  dataItemsPerPage: 40,
  isLoadingData: true,
  isLoadingNumberOfMutationsWithinFilter: true,
  numberOfDatapointsWithinFilter: 0,
  sorted: [],
};

export default function(state = initialDataState, action) {
  switch (action.type) {
    case 'FETCHING_DATA':
      return {
        ...state,
        isLoadingData: true,
      };
    case 'FETCH_DATA_SUCCESS':
      return {
        ...state,
        currentData: action.data.data,
        isLoadingData: false,
      };
    case 'FETCHING_DATA_LENGTH':
      return {
        ...state,
        isLoadingNumberOfMutationsWithinFilter: true,
      };
    case 'FETCH_DATA_LENGTH_SUCCESS':
      return {
        ...state,
        isLoadingNumberOfMutationsWithinFilter: false,
        numberOfDatapointsWithinFilter: action.data.data,
      };
    case 'NEXT_PAGE':
      return {
        ...state,
        currentDataPage: state.currentDataPage + 1,
      };
    case 'PREVIOUS_PAGE':
      return {
        ...state,
        currentDataPage: state.currentDataPage - 1,
      };
    case 'UPDATE_FILTER':
      return {
        ...state,
        currentDataPage: 1,
      };
    case 'SORT':
      return {
        ...state,
        sorted: action.value.value,
        currentDataPage: 1,
      };
    default:
      return state;
  }
}

import React from 'react';
import { connect } from 'react-redux';

import DataTable from './DataTable';

class DataContainer extends React.Component {
  render() {
    return (
      <div className="data-container">
        <DataTable />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(DataContainer);

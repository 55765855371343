import React from 'react';
import { connect } from 'react-redux';
import equal from 'deep-equal';
import { find as _find } from 'lodash';
import { sumBy as _sumBy } from 'lodash';
import { updateFilter } from './actions';

import { updateData, updateDataLength } from '../../utils/updateData';
import styled from 'styled-components';

import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import {
  withStyles,
  MenuItem,
  FilledInput,
  Select,
  FormControl,
  TextField,
  Button,
} from '@material-ui/core';

const SearchBarStyled = styled(TextField)`
  width: 100%;
`;

const TextBetweenDropdowns = styled.span`
  display: inline-block;
  justify-content: center;
  text-align: center;
  padding: 20px;
`;

const MenuItemStyled = styled(MenuItem)`
  padding: 2px; !
  text-align: center; !
`;

const DataCountText = styled.div`
  text-align: center;
`;

const FilterRow = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const MutationForm = styled.form`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const ToggleContainer = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const ToggleButtonStyle = {
  minHeight: '32px',
  height: 'auto',
  width: '100%',
  textTransform: 'none'
};


const styles = () => ({});

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = { downloadingCsv: false };
  }

  searchHandler = event => {
    const { dispatch } = this.props;
    dispatch(
      updateFilter({
        filterType: 'search',
        filterValue: event.target.value,
      }),
    );
  };

  findKeyInStore(data, key) {
    //console.log(key);
    //console.log(_.find(this.props.data.numberOfDatapointsWithinFilter.transitions, [ 'key', key ] ));
    let found = _find(this.props.data.numberOfDatapointsWithinFilter[data], ['key', key ]);
    if(typeof(found) !== 'undefined') {
      return found.count;
    }
    return 0;
  }

  sumKeyInStore(data) {
    let total = 0;
    total = _sumBy(this.props.data.numberOfDatapointsWithinFilter[data], 'count');
    return total;
  }

  filterHandler(filterType) {
    const { dispatch } = this.props;
    return (event, filterValue) => {
      dispatch(
        updateFilter({
          filterType: filterType,
          filterValue: filterValue,
        }),
      );
    };
  }

  componentDidMount() {
    const { dispatch, filter, data } = this.props;
    updateDataLength(dispatch, filter, data);
    // this.updateData()
  }

  componentDidUpdate(prevProps) {
    const { dispatch, filter, data } = this.props;
    // const { filter } = this.props
    if (!equal(filter, prevProps.filter)) {
      updateData(dispatch, filter, data);
      updateDataLength(dispatch, filter, data);
    }
  }

  render() {
    const { classes, filter, data, counts } = this.props;
    //console.log(data);
    //console.log('total wild count');
    console.log( this.sumKeyInStore('wild'));
    const nucleicAcids = ['A', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'V', 'W', 'Y']
    return (
      <div className="filters">
        <DataCountText>
          {data.isLoadingNumberOfMutationsWithinFilter ? (
            <p>
              <em>Loading mutation count...</em>
            </p>
          ) : (
            <p>
              <strong>{ data.numberOfDatapointsWithinFilter.total }</strong> mutations within current filter
            </p>
          )}
        </DataCountText>
        <FilterRow className="flex-wrap xl:flex-no-wrap">
          <ToggleContainer className="my-0 mx-auto">
            <ToggleButtonGroup
              value={filter.transitionTypes}
              exclusive
              onChange={this.filterHandler('transitionTypes')}
            >

                <ToggleButton
                    style={ ToggleButtonStyle }
                    value=""
                    children={ "ALL TRANSITIONS (" + this.sumKeyInStore('transitions') +")"}
                />

              <ToggleButton
                  style={ ToggleButtonStyle }
                  value="DtoO"
                  children={ "DISORDER TO ORDER (DtoO) ("+ this.findKeyInStore('transitions','dtoo')  +")" }
                />

              <ToggleButton
                  style={  ToggleButtonStyle }
                  value="DtoD"
                  children={ "DISORDER TO DISORDER (DtoD) ("+ this.findKeyInStore('transitions','dtod')  +")" }
              />

              <ToggleButton
                  style={ ToggleButtonStyle }
                  value="OtoD"
                  children={ "ORDER TO DISORDER (OtoD) ("+ this.findKeyInStore('transitions','otod')  +")" }
              />

              <ToggleButton
                style={ ToggleButtonStyle }
                value="OtoO"
                children={ "ORDER TO ORDER (OtoO) ("+ this.findKeyInStore('transitions','otoo')  +")" }
              />
            </ToggleButtonGroup>
          </ToggleContainer>
          <ToggleContainer className="filter-full-width">
            <ToggleButtonGroup
              value={filter.variantTypes}
              onChange={this.filterHandler('variantTypes')}
            >
              <ToggleButton
                  style={ ToggleButtonStyle }
                  value="Polymorphism"
                  children={ "Polymorphism ("+ this.findKeyInStore('variants','polymorphism')  +")" }
              />
              <ToggleButton
                  style={ ToggleButtonStyle }
                  value="Disease"
                  children={ "Disease ("+ this.findKeyInStore('variants','disease')  +")" }
              />
              <ToggleButton
                  style={ ToggleButtonStyle }
                  value="Unclassified"
                  children={ "Unclassified ("+ this.findKeyInStore('variants','unclassified')  +")" }
              />
            </ToggleButtonGroup>
          </ToggleContainer>
        </FilterRow>
        <FilterRow>
          <MutationForm>
            <FormControl variant="filled" className={classes.formControl}>
              <Select
                value={filter.wild}
                onChange={this.filterHandler('wild')}
                input={<FilledInput name="wild" id="filled-age-simple" />}
                displayEmpty
              >
                <MenuItemStyled style={{ padding: '2px', paddingLeft: '15px' }} value="">
                  Any ({this.sumKeyInStore('wild')})
                </MenuItemStyled>
                {nucleicAcids.map(item => (
                  <MenuItemStyled key={item} style={{ padding: '2px', paddingLeft: '15px' }} value={item}>
                    {item} ({this.findKeyInStore('wild', item.toLowerCase())})
                  </MenuItemStyled>
                ))}

              </Select>
            </FormControl>
            <TextBetweenDropdowns>mutated to</TextBetweenDropdowns>
            <FormControl variant="filled" className={classes.formControl}>
              <Select
                value={filter.mut}
                onChange={this.filterHandler('mut')}
                input={<FilledInput name="mut" />}
                displayEmpty
              >
                <MenuItemStyled style={{ padding: '2px', paddingLeft: '15px' }} value="">
                  Any ({this.sumKeyInStore('mut') })
                </MenuItemStyled>
                {nucleicAcids.map(item => (
                  <MenuItemStyled key={item} style={{ padding: '2px', paddingLeft: '15px' }} value={item}>
                    {item} ({this.findKeyInStore('mut', item.toLowerCase())})
                  </MenuItemStyled>
                ))}

              </Select>
            </FormControl>
          </MutationForm>
        </FilterRow>
        <SearchBarStyled
          value={filter.search.searchText}
          onChange={this.searchHandler}
          id="filled-search"
          label="Search by Protein or Disease Name"
          type="search"
          className={classes.textField}
          margin="normal"
          variant="filled"
        />
        <Button
          onClick={() => {
            const { filter, data } = this.props;
            const downloadCsv = async () => {
              this.setState({ downloadCsv: true })
              const response = await fetch(`${process.env.API_BASE_URL || 'https://api.d2odb.org'}/api/data/mutations/csv`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  query: {
                      transition: filter.transitionTypes.length !== 0 ? filter.transitionTypes : undefined,
                      wild: filter.wild.length !== 0 ? filter.wild : undefined,
                      mut: filter.mut.length !== 0 ? filter.mut : undefined,
                      Disease_name: filter.search.searchText,
                      Protein_names: filter.search.searchText,
                      sorted: data.sorted || [],
                      Variant_Type: {
                          $in: filter.variantTypes
                      }
                  }
                })
              })
              const blob = await response.blob()
              console.log(blob)

              const a = document.createElement("a");
              document.body.appendChild(a)
              const url = window.URL.createObjectURL(blob)
              a.href = url
              a.download = 'd2odb.csv'
              a.click()
              window.URL.revokeObjectURL(url)
              this.setState({ downloadCsv: false })
            }
            downloadCsv()
          }}
        >
          Download Results{this.state.downloadCsv && ' (Downloading...)'}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.data,
  filter: state.filter,
});

export default connect(mapStateToProps)(withStyles(styles)(Filters));

const initialFilterState = {
  transitionTypes: '',
  wild: '',
  mut: '',
  variantTypes: ['Polymorphism', 'Disease', 'Unclassified'],
  search: {
    searchText: '',
  },
};

export default function(state = initialFilterState, action) {
  switch (action.type) {
    case 'UPDATE_FILTER':
      switch (action.filterType) {
        case 'transitionTypes':
          return { ...state, transitionTypes: action.filterValue };
        case 'variantTypes':
          return { ...state, variantTypes: action.filterValue };
        case 'search':
          return { ...state, search: { searchText: action.filterValue } };
        case 'wild':
          return { ...state, wild: action.filterValue.props.value };
        case 'mut':
          return { ...state, mut: action.filterValue.props.value };
        default:
          return state;
      }
    default:
      return state;
  }
}
